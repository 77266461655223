<template>
  <v-container
    fluid
    class="light-blue lighten-5 pb-16 px-1"
  >
    <v-row class="mb-0 d-flex justify-center align-center">
      <!-- <v-snackbar
        v-model="snackbar"
        xs12
        multi-line
        :timeout="timeout"
        top
        color="orange"
      >
        {{ errorMessage }}
        <v-btn
          color="white"
          text
          @click="resetErrorResponse"
        >
          Close
        </v-btn>
      </v-snackbar> -->
      <v-col
        cols="12"
        sm="12"
        md="9"
        class="pb-0 mb-0"
      >
        <v-card
          elevation="0"
          class="white px-auto"
        >
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete-icon="mdi-airplane-check"
              >
                TRIP INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                complete-icon="mdi-airplane-check"
              >
                CHOOSE PLAN
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                complete-icon="mdi-airplane"
                step="3"
              >
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                complete-icon="mdi-airplane"
                step="4"
              >
                DOWNLOAD YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="loading"
      class="mt-12 vh-75"
    >
      <v-col class="d-flex justify-center">
        <ellipse-progress
          :loading="loading"
          :size="180"
          img-src="src/assets/images/Travel-Icon.png"
          thickness="5"
          empty-thickness="3"
          line-mode="out 5"
          legend="false"
          animation="rs 700 1000"
          font-size="1.5rem"
          color="#00A1B7"
          empty-color-fill="transparent"
          filename="Travel-Icon.png"
        >
        </ellipse-progress>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center mt-0 pb-16">
      <v-col
        cols="12"
        sm="11"
        md="9"
        class="mt-0 pt-1"
      >
        <v-card class="mt-0">
          <validation-observer
            ref="observer"
            v-slot="{ invalid }"
          >
            <v-form
              v-if="!loading"
              ref="form"
              class="px-8 font-weight-bold"
              @submit.prevent="next"
            >
              <v-row>
                <v-col>
                  <div class="primary--text text-center text-h1 mt-2 mb-4">
                    Travel Details
                  </div>
                </v-col>
              </v-row>

              <!------------------------Select: Details ----------------------------->
              <v-card
                v-if="!mobileDevice"
                elevation="0"
              >
                <v-row>
                  <v-col
                    cols="3"
                    sm="4"
                  >
                    <v-card-text class="text-h3 ml-4 primary--text">
                      Origin
                    </v-card-text>
                  </v-col>
                  <v-col
                    cols="9"
                    sm="8"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Origin"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="origin"
                        :items="provinces"
                        :error-messages="errors"
                        outlined
                        @click="
                          clickedFieldGlobal(
                            'travelInfo origin',
                            1,
                            'Canadian Travel Survey v1'
                          )
                        "
                      >
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col
                    cols="3"
                    sm="4"
                  >
                    <v-card-text class="text-h3 ml-4 primary--text">
                      Destination
                    </v-card-text>
                  </v-col>
                  <v-col
                    cols="9"
                    sm="8"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Destination"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="destination"
                        :items="places"
                        item-text="Label"
                        return-object
                        :error-messages="errors"
                        outlined
                        @click="
                          clickedFieldGlobal(
                            'travelInfo destination',
                            2,
                            'Canadian Travel Survey v1'
                          )
                        "
                      >
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
                <!-----------------------Dates ----------------------------------->
                <v-row class="d-flex">
                  <v-col
                    cols="3"
                    sm="6"
                  >
                    <v-card-text class="text-h3 ml-4 primary--text">
                      Trip Start Date
                    </v-card-text>
                  </v-col>
                  <v-col
                    cols="3"
                    sm="6"
                  >
                    <v-dialog
                      ref="dialog1"
                      v-model="modal1"
                      :return-value.sync="startDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Start Date"
                          rules="required|customBefore:@endDateToCheck"
                        >
                          <v-text-field
                            v-model="startDate"
                            :error-messages="errors"
                            readonly
                            v-bind="attrs"
                            outlined
                            v-on="on"
                            @click="
                              clickedFieldGlobal(
                                'travelInfo startDate',
                                3,
                                'Canadian Travel Survey v1'
                              )
                            "
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        scrollable
                        width="290"
                        :min="resetDate"
                        :max="maxDate"
                        @change="setEndDate"
                      >
                        <div class="d-flex justify-space-between">
                          <v-btn
                            text
                            color="primary"
                            width="30"
                            @click="
                              modal1 = false;
                              clickedFieldGlobal(
                                'travelInfo startDateCancelBtn',
                                4,
                                'Canadian Travel Survey v1'
                              );
                            "
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              startDate = resetDate;
                              clickedFieldGlobal(
                                'travelInfo startDateResetBtn',
                                5,
                                'Canadian Travel Survey v1'
                              );
                            "
                          >
                            Reset
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            width="30"
                            @click="
                              $refs.dialog1.save(startDate);
                              clickedFieldGlobal(
                                'travelInfo startDateOkBtn',
                                6,
                                'Canadian Travel Survey v1'
                              );
                            "
                          >
                            OK
                          </v-btn>
                        </div>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                  <v-col
                    cols="3"
                    sm="6"
                  >
                    <v-card-text class="text-h3 ml-4 primary--text">
                      Trip End Date
                    </v-card-text>
                  </v-col>
                  <v-col
                    cols="3"
                    sm="6"
                  >
                    <v-dialog
                      ref="dialog2"
                      v-model="modal2"
                      :return-value.sync="endDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="endDateToCheck"
                          rules="required"
                        >
                          <v-text-field
                            v-model="endDate"
                            :error-messages="errors"
                            readonly
                            v-bind="attrs"
                            outlined
                            v-on="on"
                            @click="
                              clickedFieldGlobal(
                                'travelInfo endDate',
                                7,
                                'Canadian Travel Survey v1'
                              )
                            "
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        scrollable
                        width="290"
                        :min="minEndDate"
                        :max="maxDate"
                      >
                        <div class="d-flex justify-space-between">
                          <v-btn
                            text
                            color="primary"
                            width="30"
                            @click="
                              modal2 = false;
                              clickedFieldGlobal(
                                'travelInfo endDateCancelBtn',
                                8,
                                'Canadian Travel Survey v1'
                              );
                            "
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              endDate = minEndDate;
                              clickedFieldGlobal(
                                'travelInfo endDateResetBtn',
                                9,
                                'Canadian Travel Survey v1'
                              );
                            "
                          >
                            Reset
                          </v-btn>
                          <v-btn
                            text
                            width="30"
                            color="primary"
                            @click="
                              $refs.dialog2.save(endDate);
                              clickedFieldGlobal(
                                'travelInfo endDateOkBtn',
                                10,
                                'Canadian Travel Survey v1'
                              );
                            "
                          >
                            OK
                          </v-btn>
                        </div>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-card-text class="text-h3 ml-4 primary--text">
                      Trip Cost
                    </v-card-text>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Trip Cost"
                      rules="between:1,50000|numeric|required"
                    >
                      <v-text-field
                        v-model="tripCost"
                        :error-messages="errors"
                        prefix="$"
                        suffix="/Traveller"
                        outlined
                        @click="
                          clickedFieldGlobal(
                            'travelInfo tripCost',
                            11,
                            'Canadian Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="6">
                    <v-card-text class="text-h3 ml-4 primary--text">
                      Primary Traveller Name
                    </v-card-text>
                  </v-col>
                  <v-col cols="6">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Primary Traveller Name"
                      :rules="{
                        required: true,
                        max: 150,
                        regex: /^[a-zA-Z \-]+$/
                      }"
                    >
                      <v-text-field
                        v-model="primaryTravellerName"
                        :error-messages="errors"
                        outlined
                        @click="
                          clickedFieldGlobal(
                            'travelInfo primaryTravName',
                            12,
                            'Canadian Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row class="d-flex">
                  <v-col
                    cols="3"
                    sm="6"
                  >
                    <v-card-text class="text-h3 ml-4 primary--text">
                      Birth Date
                    </v-card-text>
                  </v-col>
                  <v-col
                    cols="3"
                    sm="6"
                  >
                    <v-menu
                      ref="birthDateMenu"
                      v-model="birthDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Birth Date"
                          rules="required"
                        >
                          <v-text-field
                            v-model="birthDate"
                            :error-messages="errors"
                            :disabled="primaryTravellerName.length === 0"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="birthDate"
                        :active-picker.sync="activeBirthDatePicker"
                        first-day-of-week="1"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="e => saveBirthDate(e, 'birthDateMenu')"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>

                <v-row
                  v-for="(item, index) in additionalTravelers"
                  :key="item.id"
                >
                  <v-col cols="12">
                    <v-divider class="primary ma-4"></v-divider>
                  </v-col>

                  <v-col cols="3">
                    <v-card-text class="text-h3 ml-4 primary--text">
                      Traveller Name
                    </v-card-text>
                  </v-col>
                  <v-col cols="9">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="additionalTraveler[index]"
                      :rules="{ required: true, regex: /^[a-zA-Z \-]+$/ }"
                    >
                      <v-text-field
                        v-model="item.name"
                        :error-messages="errors"
                        outlined
                        @input="debouncedSave(item)"
                        @click="clickedFieldGlobal(
                          'travelInfo additionalTravName',
                          13,
                          'Canadian Travel Survey v1'
                        )"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                  <v-col cols="3">
                    <v-card-text class="text-h3 ml-4 primary--text">
                      Birth Date
                    </v-card-text>
                  </v-col>
                  <v-col cols="9">
                    <v-menu
                      :ref="item.debouncedName.length > 0 && `${item.debouncedName}-birthDateMenu`"
                      v-model="item.birthDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Birth Date"
                          rules="required"
                        >
                          <v-text-field
                            v-model="item.birthdate"
                            :error-messages="errors"
                            :disabled="item.name.length === 0"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            @click="setActiveAdditionalTravelerIndex(index); clickedFieldGlobal(
                              'travelInfo additionalTravBdate',
                              14,
                              'Canadian Travel Survey v1'
                            )"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="item.birthdate"
                        :active-picker.sync="item.activeBirthDatePicker"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        first-day-of-week="1"
                        min="1950-01-01"
                        @change="(e) => saveBirthDate(e, `${item.debouncedName}-birthDateMenu`)"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12 pl-">
                    <v-btn
                      color="warning"
                      @click="
                        removeTraveler(item, index);
                        clickedFieldGlobal(
                          'travelInfo removeTraveller',
                          15,
                          'Canadian Travel Survey v1'
                        );
                      "
                    >
                      Remove Traveller
                    </v-btn>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    class=""
                    @click="
                      addTravelerCount();
                      clickedFieldGlobal(
                        'travelInfo addTraveller',
                        16,
                        'Canadian Travel Survey v1'
                      );
                    "
                  >
                    Add Traveler
                  </v-btn>
                  <v-btn
                    v-if="!over60"
                    :disabled="invalid || birthDayError"
                    color="primary"
                    type="submit"
                    @click="
                      next();
                      clickedFieldGlobal(
                        'travelInfo getQuote',
                        17,
                        'Canadian Travel Survey v1'
                      );
                    "
                  >
                    Get Quote
                  </v-btn>
                </v-card-actions>
              </v-card>

              <!---------------------------------------------MOBILE------------------------------------------------------>
              <div v-if="mobileDevice">
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Origin"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="origin"
                        :items="provinces"
                        :error-messages="errors"
                        label="Origin"
                        outlined
                        @click="
                          clickedFieldGlobal(
                            'travelInfo originMobile',
                            18,
                            'Canadian Travel Survey v1'
                          )
                        "
                      >
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row class="d-flex justify-center">
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Destination"
                      rules="required"
                    >
                      <v-autocomplete
                        v-model="destination"
                        :items="places"
                        item-text="Label"
                        return-object
                        :error-messages="errors"
                        label="Destination"
                        outlined
                        @click="
                          clickedFieldGlobal(
                            'travelInfo destinationMobile',
                            19,
                            'Canadian Travel Survey v1'
                          )
                        "
                      >
                      </v-autocomplete>
                    </validation-provider>
                  </v-col>
                </v-row>
                <!-----------------------Dates ----------------------------------->
                <v-row class="d-flex">
                  <v-col cols="12">
                    <v-dialog
                      ref="dialog1"
                      v-model="modal1"
                      :return-value.sync="startDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Start Date"
                          rules="required"
                        >
                          <v-text-field
                            v-model="startDate"
                            :error-messages="errors"
                            readonly
                            label="Start Date"
                            v-bind="attrs"
                            outlined
                            v-on="on"
                            @click="
                              clickedFieldGlobal(
                                'travelInfo startDateMobile',
                                20,
                                'Canadian Travel Survey v1'
                              )
                            "
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        scrollable
                        width="290"
                        :min="resetDate"
                        :max="maxDate"
                      >
                        <div class="d-flex justify-space-between">
                          <v-btn
                            text
                            color="primary"
                            width="30"
                            @click="
                              modal1 = false;
                              clickedFieldGlobal(
                                'travelInfo startDateMobileCancelBtn',
                                21,
                                'Canadian Travel Survey v1'
                              );
                            "
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              startDate = resetDate;
                              clickedFieldGlobal(
                                'travelInfo startDateMobileResetBtn',
                                22,
                                'Canadian Travel Survey v1'
                              );
                            "
                          >
                            Reset
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            text
                            color="primary"
                            width="30"
                            @click="
                              $refs.dialog1.save(startDate);
                              clickedFieldGlobal(
                                'travelInfo startDateMobileOkBtn',
                                23,
                                'Canadian Travel Survey v1'
                              );
                            "
                          >
                            OK
                          </v-btn>
                        </div>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-dialog
                      ref="dialog2"
                      v-model="modal2"
                      :return-value.sync="endDate"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="End Date"
                          rules="required"
                        >
                          <v-text-field
                            v-model="endDate"
                            :error-messages="errors"
                            readonly
                            label="End Date"
                            v-bind="attrs"
                            outlined
                            v-on="on"
                            @click="
                              clickedFieldGlobal(
                                'travelInfo endDateMobile',
                                24,
                                'Canadian Travel Survey v1'
                              )
                            "
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        scrollable
                        width="290"
                        :min="minEndDate"
                        :max="maxDate"
                      >
                        <div class="d-flex justify-space-between">
                          <v-btn
                            text
                            color="primary"
                            width="30"
                            @click="
                              modal2 = false;
                              clickedFieldGlobal(
                                'travelInfo endDateMobileCancelBtn',
                                25,
                                'Canadian Travel Survey v1'
                              );
                            "
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              endDate = minEndDate;
                              clickedFieldGlobal(
                                'travelInfo endDateMobileResetBtn',
                                26,
                                'Canadian Travel Survey v1'
                              );
                            "
                          >
                            Reset
                          </v-btn>
                          <v-btn
                            text
                            width="30"
                            color="primary"
                            @click="
                              $refs.dialog2.save(endDate);
                              clickedFieldGlobal(
                                'travelInfo endDateMobileOkBtn',
                                27,
                                'Canadian Travel Survey v1'
                              );
                            "
                          >
                            OK
                          </v-btn>
                        </div>
                      </v-date-picker>
                    </v-dialog>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Trip Cost"
                      rules="between:1,50000|numeric|required"
                    >
                      <v-text-field
                        v-model="tripCost"
                        :error-messages="errors"
                        label="Trip Cost"
                        prefix="$"
                        suffix="/Traveller"
                        outlined
                        @click="
                          clickedFieldGlobal(
                            'travelInfo tripCostMobile',
                            28,
                            'Canadian Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-card-text class="text-h3 ml-4 primary--text">
                      Primary Traveller Name
                    </v-card-text>
                  </v-col>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      name="Primary Traveller Name"
                      :rules="{
                        required: true,
                        max: 150,
                        regex: /^[a-zA-Z \-]+$/
                      }"
                    >
                      <v-text-field
                        v-model="primaryTravellerName"
                        :error-messages="errors"
                        outlined
                        @click="
                          clickedFieldGlobal(
                            'travelInfo primaryTravNameMobile',
                            29,
                            'Canadian Travel Survey v1'
                          )
                        "
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row class="d-flex flex-column">
                  <v-col>
                    <v-card-text class="text-h3 ml-4 primary--text">
                      Birth Date
                    </v-card-text>
                  </v-col>
                  <v-col>
                    <v-menu
                      ref="birthDateMenu"
                      v-model="birthDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Birth Date"
                          rules="required"
                        >
                          <v-text-field
                            v-model="birthDate"
                            :error-messages="errors"
                            :disabled="primaryTravellerName.length === 0"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="birthDate"
                        :active-picker.sync="activeBirthDatePicker"
                        first-day-of-week="1"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="e => saveBirthDate(e, 'birthDateMenu')"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row
                  v-for="(item, index) in additionalTravelers"
                  :key="item.id"
                >
                  <v-col cols="12">
                    <v-divider class="primary ma-4"></v-divider>
                  </v-col>

                  <v-col cols="12">
                    <v-card-text class="text-h3 ml-4 primary--text">
                      Traveller Name
                    </v-card-text>
                  </v-col>
                  <v-col cols="12">
                    <validation-provider
                      v-slot="{ errors }"
                      :name="additionalTraveler[index]"
                      :rules="{ required: true, regex: /^[a-zA-Z \-]+$/ }"
                    >
                      <v-text-field
                        v-model="item.name"
                        :error-messages="errors"
                        outlined
                        @input="debouncedSave(item)"
                        @click="clickedFieldGlobal(
                          'additionalTravNameMobile',
                          30,
                          'Canadian Travel Survey v1'
                        )"
                      >
                      </v-text-field>
                    </validation-provider>
                  </v-col>

                  <v-col cols="12">
                    <v-card-text class="text-h3 ml-4 primary--text">
                      Birth Date
                    </v-card-text>
                  </v-col>
                  <v-col cols="12">
                    <v-menu
                      :ref="item.debouncedName.length > 0 && `${item.debouncedName}-birthDateMenu`"
                      v-model="item.birthDateMenu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Birth Date"
                          rules="required"
                        >
                          <v-text-field
                            v-model="item.birthdate"
                            :error-messages="errors"
                            :disabled="item.name.length === 0"
                            readonly
                            outlined
                            v-bind="attrs"
                            v-on="on"
                            @click="setActiveAdditionalTravelerIndex(index); clickedFieldGlobal(
                              'additionalTravBdateMobile',
                              31,
                              'Canadian Travel Survey v1'
                            )"
                          ></v-text-field>
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="item.birthdate"
                        :active-picker.sync="item.activeBirthDatePicker"
                        :max="(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)"
                        first-day-of-week="1"
                        min="1950-01-01"
                        @change="(e) => saveBirthDate(e, `${item.debouncedName}-birthDateMenu`)"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <v-col cols="12 pl-8">
                    <v-btn
                      color="warning"
                      @click="
                        removeTraveler(item, index);
                        clickedFieldGlobal(
                          'travelInfo removeTravelerMobile',
                          32,
                          'Canadian Travel Survey v1'
                        );
                      "
                    >
                      Remove Traveller
                    </v-btn>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    @click="
                      addTravelerCount();
                      clickedFieldGlobal(
                        'travelInfo addTravelerMobile',
                        33,
                        'Canadian Travel Survey v1'
                      );
                    "
                  >
                    Add Traveler
                  </v-btn>
                  <v-btn
                    v-if="!over60"
                    :disabled="invalid || birthDayError || loading"
                    color="primary"
                    @click="
                      next();
                      clickedFieldGlobal(
                        'travelInfo getQuoteMobile',
                        34,
                        'Canadian Travel Survey v1'
                      );
                    "
                  >
                    Get Quote
                  </v-btn>
                </v-card-actions>
              </div>
              <!-------------------------Travellers---------------------------->
              <div v-if="!mobileDevice">
                <v-dialog
                  v-model="dialog5"
                  width="800"
                >
                  <v-card class="pa-4">
                    <v-card-text class="text-h2 text-center primary--text">
                      Add Travellers
                    </v-card-text>
                    <v-row class="d-flex">
                      <v-col cols="5">
                        <v-card-text class="text-h3 ml-4 primary--text">
                          Number of Travellers
                        </v-card-text>
                      </v-col>
                      <v-col cols="7">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Travellers"
                          rules="required"
                        >
                          <v-select
                            v-model="numberOfTravellers"
                            :items="numbers"
                            :error-messages="errors"
                            outlined
                            @click="
                              clickedFieldGlobal(
                                'travelInfo numberOfTravellers',
                                35,
                                'Canadian Travel Survey v1'
                              )
                            "
                          >
                          </v-select>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-space-between">
                      <v-col cols="5">
                        <v-card-text class="text-h3 ml-4 primary--text">
                          Primary Traveller Name
                        </v-card-text>
                      </v-col>
                      <v-col cols="7">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Primary Traveller Name"
                          :rules="{
                            required: true,
                            max: 150,
                            regex: /^[a-zA-Z \-]+$/
                          }"
                        >
                          <v-text-field
                            v-model="primaryTravellerName"
                            :error-messages="errors"
                            outlined
                            @click="
                              clickedFieldGlobal(
                                'travelInfo primaryTravName',
                                36,
                                'Canadian Travel Survey v1'
                              )
                            "
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="5">
                        <v-card-text class="text-h3 ml-4 primary--text">
                          Primary Traveller Birthdate
                        </v-card-text>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Birth Day"
                          rules="required"
                        >
                          <v-select
                            v-model="bdays"
                            :items="daysavailable"
                            label="Day"
                            outlined
                            color="primary"
                            :error-messages="errors"
                            @change="
                              calcbday, checkDate(bdays, bmonths, byears)
                            "
                            @click="
                              clickedFieldGlobal(
                                'travelInfo primaryTravBdays',
                                37,
                                'Canadian Travel Survey v1'
                              )
                            "
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Birth Month"
                          rules="required"
                        >
                          <v-select
                            v-model="bmonths"
                            :items="monthsavailable"
                            item-text="state"
                            item-value="abbr"
                            label="Month"
                            outlined
                            color="primary"
                            :error-messages="errors"
                            @change="
                              calcbday, checkDate(bdays, bmonths, byears)
                            "
                            @click="
                              clickedFieldGlobal(
                                'travelInfo primaryTravBmonths',
                                38,
                                'Canadian Travel Survey v1'
                              )
                            "
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Birth Year"
                          rules="required"
                        >
                          <v-select
                            v-model="byears"
                            :items="yearsavailable"
                            label="Year1"
                            outlined
                            color="primary"
                            :error-messages="errors"
                            @change="
                              calcbday, checkDate(bdays, bmonths, byears)
                            "
                            @click="
                              clickedFieldGlobal(
                                'travelInfo primaryTravByears',
                                39,
                                'Canadian Travel Survey v1'
                              )
                            "
                          ></v-select>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <div v-if="numberOfTravellers === 2">
                      <v-row>
                        <v-col><v-divider class="black"></v-divider></v-col>
                      </v-row>
                      <v-row class="d-flex justify-space-between">
                        <v-col cols="5">
                          <v-card-text class="text-h3 ml-4 primary--text">
                            Second Traveller Name
                          </v-card-text>
                        </v-col>
                        <v-col cols="7">
                          <validation-provider
                            v-slot="{ errors }"
                            name="Second Traveller Name"
                            :rules="{ required: true, regex: /^[a-zA-Z \-]+$/ }"
                          >
                            <v-text-field
                              v-model="secondTravellerName"
                              :error-messages="errors"
                              outlined
                              @click="
                                clickedFieldGlobal(
                                  'travelInfo secondTravName',
                                  40,
                                  'Canadian Travel Survey v1'
                                )
                              "
                            >
                            </v-text-field>
                          </validation-provider>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="5">
                          <v-card-text class="text-h3 ml-4 primary--text">
                            Second Traveller Birthdate
                          </v-card-text>
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col
                          cols="12"
                          md="3"
                          class="ml-8"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Birth Day"
                            rules="required"
                          >
                            <v-select
                              v-model="bdaysSecond"
                              :items="daysavailable"
                              label="Day"
                              outlined
                              color="primary"
                              :error-messages="errors"
                              @change="calcbday"
                              @click="
                                clickedFieldGlobal(
                                  'travelInfo secondTravBdays',
                                  41,
                                  'Canadian Travel Survey v1'
                                )
                              "
                            ></v-select>
                          </validation-provider>
                        </v-col>
                        <v-col
                          cols="12"
                          md="3"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Birth Month"
                            rules="required"
                          >
                            <v-select
                              v-model="bmonthsSecond"
                              :items="monthsavailable"
                              item-text="state"
                              item-value="abbr"
                              label="Month"
                              outlined
                              color="primary"
                              :error-messages="errors"
                              @change="calcbday"
                              @click="
                                clickedFieldGlobal(
                                  'travelInfo secondTravBmonths',
                                  42,
                                  'Canadian Travel Survey v1'
                                )
                              "
                            ></v-select>
                          </validation-provider>
                        </v-col>
                        <v-col
                          cols="12"
                          md="3"
                        >
                          <validation-provider
                            v-slot="{ errors }"
                            name="Birth Year"
                            rules="required"
                          >
                            <v-select
                              v-model="byearsSecond"
                              :items="yearsavailable"
                              label="Year"
                              outlined
                              color="primary"
                              :error-messages="errors"
                              @change="calcbday"
                              @click="
                                clickedFieldGlobal(
                                  'travelInfo secondTravByears',
                                  43,
                                  'Canadian Travel Survey v1'
                                )
                              "
                            ></v-select>
                          </validation-provider>
                        </v-col>
                      </v-row>
                    </div>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        @click="
                          dialog5 = false;
                          showButton = true;
                          clickedFieldGlobal(
                            'travelInfo closeDialog5Btn',
                            44,
                            'Canadian Travel Survey v1'
                          );
                        "
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
              <!---------------------------------------MOBILE---------------------------------------->
              <div>
                <v-dialog
                  v-model="dialog5"
                  width="800"
                >
                  <v-card class="pa-4">
                    <v-card-text class="text-h2 text-center primary--text">
                      Add Travellers
                    </v-card-text>
                    <v-row class="d-flex">
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Travellers"
                          rules="required"
                        >
                          <v-select
                            v-model="numberOfTravellers"
                            :items="numbers"
                            :error-messages="errors"
                            label="Number of Travellers"
                            outlined
                            @click="
                              clickedFieldGlobal(
                                'travelInfo numberOfTravellersMobile',
                                45,
                                'Canadian Travel Survey v1'
                              )
                            "
                          >
                          </v-select>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row class="d-flex justify-space-between">
                      <v-col cols="12">
                        <validation-provider
                          v-slot="{ errors }"
                          name="Primary Traveller Name"
                          :rules="{
                            required: true,
                            max: 150,
                            regex: /^[a-zA-Z \-]+$/
                          }"
                        >
                          <v-text-field
                            v-model="primaryTravellerName"
                            :error-messages="errors"
                            label="Primary Traveller Name"
                            outlined
                            @click="
                              clickedFieldGlobal(
                                'travelInfo primaryTravellerNameMobile',
                                46,
                                'Canadian Travel Survey v1'
                              )
                            "
                          >
                          </v-text-field>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Birth Day"
                          rules="required"
                        >
                          <v-select
                            v-model="bdays"
                            :items="daysavailable"
                            label="Birth Day"
                            outlined
                            color="primary"
                            :error-messages="errors"
                            @change="
                              calcbday, checkDate(bdays, bmonths, byears)
                            "
                            @click="
                              clickedFieldGlobal(
                                'travelInfo primaryTravBdaysMobile',
                                47,
                                'Canadian Travel Survey v1'
                              )
                            "
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Birth Month"
                          rules="required"
                        >
                          <v-select
                            v-model="bmonths"
                            :items="monthsavailable"
                            item-text="state"
                            item-value="abbr"
                            label="Birth Month"
                            outlined
                            color="primary"
                            :error-messages="errors"
                            @change="
                              calcbday, checkDate(bdays, bmonths, byears)
                            "
                            @click="
                              clickedFieldGlobal(
                                'travelInfo primaryTravBmonthsMobile',
                                48,
                                'Canadian Travel Survey v1'
                              )
                            "
                          ></v-select>
                        </validation-provider>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                      >
                        <validation-provider
                          v-slot="{ errors }"
                          name="Birth Year"
                          rules="required"
                        >
                          <v-select
                            v-model="byears"
                            :items="yearsavailable"
                            label="Birth Year"
                            outlined
                            color="primary"
                            :error-messages="errors"
                            @change="
                              calcbday, checkDate(bdays, bmonths, byears)
                            "
                            @click="
                              clickedFieldGlobal(
                                'travelInfo primaryTravByearsMobile',
                                49,
                                'Canadian Travel Survey v1'
                              )
                            "
                          ></v-select>
                        </validation-provider>
                      </v-col>
                    </v-row>
                    <div v-if="!mobileDevice">
                      <div v-if="numberOfTravellers === 2">
                        <v-row>
                          <v-col><v-divider class="black"></v-divider></v-col>
                        </v-row>
                        <v-row class="d-flex justify-space-between">
                          <v-col cols="5">
                            <v-card-text class="text-h3 ml-4 primary--text">
                              Second Traveller Name
                            </v-card-text>
                          </v-col>
                          <v-col cols="7">
                            <validation-provider
                              v-slot="{ errors }"
                              name="Second Traveller Name"
                              :rules="{
                                required: true,
                                regex: /^[a-zA-Z \-]+$/
                              }"
                            >
                              <v-text-field
                                v-model="secondTravellerName"
                                :error-messages="errors"
                                outlined
                                @click="
                                  clickedFieldGlobal(
                                    'travelInfo secondTravName',
                                    50,
                                    'Canadian Travel Survey v1'
                                  )
                                "
                              >
                              </v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="5">
                            <v-card-text class="text-h3 ml-4 primary--text">
                              Second Traveller Birthdate
                            </v-card-text>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            md="3"
                            class="ml-8"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Birth Day"
                              rules="required"
                            >
                              <v-select
                                v-model="bdaysSecond"
                                :items="daysavailable"
                                label="Day"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @change="
                                  calcbday,
                                  checkDate(
                                    bdaysSecond,
                                    bmonthsSecond,
                                    byearsSecond
                                  )
                                "
                                @click="
                                  clickedFieldGlobal(
                                    'travelInfo secondTravBdays',
                                    51,
                                    'Canadian Travel Survey v1'
                                  )
                                "
                              ></v-select>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Birth Month"
                              rules="required"
                            >
                              <v-select
                                v-model="bmonthsSecond"
                                :items="monthsavailable"
                                item-text="state"
                                item-value="abbr"
                                label="Birth Month"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @change="
                                  calcbday,
                                  checkDate(
                                    bdaysSecond,
                                    bmonthsSecond,
                                    byearsSecond
                                  )
                                "
                                @click="
                                  clickedFieldGlobal(
                                    'travelInfo secondTravBmonths',
                                    52,
                                    'Canadian Travel Survey v1'
                                  )
                                "
                              ></v-select>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Birth Year"
                              rules="required"
                            >
                              <v-select
                                v-model="byearsSecond"
                                :items="yearsavailable"
                                label="Birth Year"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @change="
                                  calcbday,
                                  checkDate(
                                    bdaysSecond,
                                    bmonthsSecond,
                                    byearsSecond
                                  )
                                "
                                @click="
                                  clickedFieldGlobal(
                                    'travelInfo secondTravByears',
                                    53,
                                    'Canadian Travel Survey v1'
                                  )
                                "
                              ></v-select>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                    <!----------------------------------MOBILE DEVICE---------------------------------->
                    <div v-if="mobileDevice">
                      <div v-if="numberOfTravellers === 2">
                        <v-row>
                          <v-col><v-divider class="black"></v-divider></v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="12">
                            <validation-provider
                              v-slot="{ errors }"
                              name="Second Traveller Name"
                              :rules="{
                                required: true,
                                regex: /^[a-zA-Z \-]+$/
                              }"
                            >
                              <v-text-field
                                v-model="secondTravellerName"
                                :error-messages="errors"
                                label="Second Traveller Name"
                                outlined
                                @click="
                                  clickedFieldGlobal(
                                    'travelInfo secondTravNameMobile',
                                    54,
                                    'Canadian Travel Survey v1'
                                  )
                                "
                              >
                              </v-text-field>
                            </validation-provider>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            md="3"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Birth Day"
                              rules="required"
                            >
                              <v-select
                                v-model="bdaysSecond"
                                :items="daysavailable"
                                label="Birth Day"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @change="calcbday"
                                @click="
                                  clickedFieldGlobal(
                                    'travelInfo secondTravBdaysMobile',
                                    55,
                                    'Canadian Travel Survey v1'
                                  )
                                "
                              ></v-select>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Birth Month"
                              rules="required"
                            >
                              <v-select
                                v-model="bmonthsSecond"
                                :items="monthsavailable"
                                item-text="state"
                                item-value="abbr"
                                label="Birth Month"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @change="calcbday"
                                @click="
                                  clickedFieldGlobal(
                                    'travelInfo secondTravBmonths',
                                    56,
                                    'Canadian Travel Survey v1'
                                  )
                                "
                              ></v-select>
                            </validation-provider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="3"
                          >
                            <validation-provider
                              v-slot="{ errors }"
                              name="Birth Year"
                              rules="required"
                            >
                              <v-select
                                v-model="byearsSecond"
                                :items="yearsavailable"
                                label="Birth Year"
                                outlined
                                color="primary"
                                :error-messages="errors"
                                @change="calcbday"
                                @click="
                                  clickedFieldGlobal(
                                    'travelInfo secondTravByears',
                                    57,
                                    'Canadian Travel Survey v1'
                                  )
                                "
                              ></v-select>
                            </validation-provider>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        @click="
                          dialog5 = false;
                          showButton = true;
                          clickedFieldGlobal(
                            'travelInfo dialog5MobileCloseBtn',
                            58,
                            'Canadian Travel Survey v1'
                          );
                        "
                      >
                        Close
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
              <!--------------------------Medical Survey----------------------------->
              <div v-if="over60 && tripLength > 2">
                <v-row class="d-flex justify-center">
                  <v-col cols="12">
                    <v-alert
                      dark
                      class="text-center primary"
                    >
                      <span
                        class="text-h5 text-center"
                      >Before we can continue, we'll need the medical
                        questionnaire below filled out by the traveller
                        indicated here. Thanks!</span>
                    </v-alert>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card-title class="primary--text text-h2">
                      Medical Questionnaire
                    </v-card-title>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-card-subtitle class="font-weight-bold">
                      ALL applicants 60 years of age and older MUST answer the
                      following questions to determine the rate.
                    </v-card-subtitle>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="pt-0">
                    <v-card-text>
                      It’s important to answer the Medical Questionnaire
                      correctly. If you qualify for the insurance but you or a
                      representative buying insurance on your behalf
                      inaccurately answer any of the Rate Qualification
                      Questions, a $15,000 USD deductible applies to any
                      incident claimed, in addition to any other deductible you
                      may have selected. At the time of a claim, if the answers
                      are not complete or accurate, coverage won’t be provided
                      until you correct your answers, and if applicable, you pay
                      any additional premium that may be required.
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="pt-0">
                    <div>
                      <v-dialog
                        v-model="dialog3"
                        width="800"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            class="font-weight-bold"
                            dark
                            text
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              clickedFieldGlobal(
                                'travelInfo medQuestionnaireDialog',
                                59,
                                'Canadian Travel Survey v1'
                              )
                            "
                          >
                            <v-icon left>
                              mdi-information
                            </v-icon>
                            What You Need to Know about the Medical
                            Questionnaire (MQ)
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-title class="text-h3">
                            What You Need to Know about the Medical
                            Questionnaire (MQ)
                          </v-card-title>

                          <v-card-text>
                            <span
                              class="font-weight-bold"
                            >Why do I need to complete the Medical
                              Questionnaire?</span>
                            <br />
                            Your answers will determine the premium (cost) of
                            your insurance.
                          </v-card-text>

                          <v-card-text>
                            <span
                              class="font-weight-bold"
                            >What should I do if I don’t know how to answer a
                              question?
                            </span>
                            <br />
                            Refer to the Medical Definitions or the Medical
                            Terminology Guide sections. If you’re still unsure,
                            talk to your doctor for advice before completing the
                            questionnaire.
                          </v-card-text>

                          <v-divider></v-divider>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="primary"
                              @click="
                                dialog3 = false;
                                clickedFieldGlobal(
                                  'travelInfo medQuestionCloseDialogBtn',
                                  60,
                                  'Canadian Travel Survey v1'
                                );
                              "
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="pt-0">
                    <div>
                      <v-dialog
                        v-model="dialog4"
                        width="800"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            class="font-weight-bold"
                            dark
                            text
                            v-bind="attrs"
                            v-on="on"
                            @click="
                              clickedFieldGlobal(
                                'travelInfo medTerminologyDialog',
                                61,
                                'Canadian Travel Survey v1'
                              )
                            "
                          >
                            <v-icon left>
                              mdi-book
                            </v-icon>
                            Medical Terminology
                          </v-btn>
                        </template>

                        <v-card>
                          <v-card-text class="text-h3">
                            Medical Terminology
                          </v-card-text>

                          <v-card-subtitle class="text-h2">
                            Medical Definitions
                          </v-card-subtitle>

                          <v-card-text class="text-h">
                            <span class="font-weight-bold">Alteration</span>
                            <br />
                            The medication usage, dosage or type has been
                            increased, decreased or stopped and/or a new
                            medication has been prescribed. Alteration does not
                            include: <br />
                            a) Changes in brand to an equivalent name brand or
                            to an equivalent generic brand of the same or
                            equivalent usage or dosage; or, <br />
                            b) Routine dosage adjustments within prescribed
                            parameters for insulin or oral diabetes medication
                            to ensure correct blood levels are maintained; blood
                            sugar levels must be checked regularly and the
                            medical condition must remain unchanged; or, <br />
                            c) Routine dosage adjustments within prescribed
                            parameters for blood thinner medication to ensure
                            correct blood levels are maintained; blood levels
                            must be checked regularly and the medical condition
                            must remain unchanged; or, <br />
                            d) A temporary stoppage of blood thinner medication
                            up to a maximum of 24 hours if the stoppage is
                            required for a surgery or a procedure; or, <br />
                            e) Usage changes due to the combination of several
                            medications into one; the medical condition must
                            remain unchanged. <br />
                            <br />
                            <span class="font-weight-bold">Remission</span>
                            <br />
                            The decrease in or the disappearance of signs and
                            symptoms of cancer and/or the removal of cancer as
                            determined by your physician and noted in your
                            medical records. Remission can be complete or
                            partial. Complete remission means the disappearance
                            of all signs or symptoms. Partial remission means a
                            decrease in or disappearance of some, but not all,
                            signs and symptoms. <br /><br />
                            <span class="font-weight-bold">Severe Obesity</span>
                            <br />
                            A weight-to-height ratio measured by Body Mass Index
                            (BMI) greater than 40. Severe obesity must be
                            diagnosed by a physician or other registered medical
                            practitioner and be recorded in your medical
                            records.
                          </v-card-text>

                          <v-card-subtitle class="text-h2">
                            Medical Terminology Guide
                          </v-card-subtitle>
                          <v-card-text>
                            The following information is for general reference
                            only. You should consult your doctor(s) for more
                            detailed information about medical conditions that
                            may affect you. <br /><br />
                            <span class="font-weight-bold">Arthritis</span>
                            <br />
                            If you’ve been diagnosed with any type of arthritis
                            such as osteoarthritis, rheumatoid arthritis or
                            psoriatic arthritis, choose this condition. <br />
                            <br />
                            <span
                              class="font-weight-bold"
                            >Atrial fibrillation</span>
                            <br />
                            Atrial fibrillation is a type of arrhythmia. Choose
                            this condition if you’ve been diagnosed specifically
                            with atrial fibrillation and not any other type of
                            arrhythmia or dysrhythmia. <br />
                            <br />
                            <span
                              class="font-weight-bold"
                            >Bladder infection</span>
                            <br />
                            A bladder infection is a specific type of Urinary
                            Tract Infection (UTI). Choose this condition if
                            you’ve had two or more bladder infections, and not
                            any other types of UTIs. <br />
                            <br />
                            <span
                              class="font-weight-bold"
                            >Coronary Artery Disease (CAD)</span>
                            <br />
                            Coronary Artery Disease can be caused by
                            arteriosclerosis and/or atherosclerosis. If you’ve
                            been diagnosed with CAD and arteriosclerosis and/or
                            atherosclerosis, choose all these conditions.<br />
                            <br />
                            <span
                              class="font-weight-bold"
                            >Diabetes not requiring insulin</span>
                            <br />
                            If you’ve been diagnosed with diabetes that’s
                            treated either by lifestyle changes such as diet,
                            exercise or with any kind of medication other than
                            insulin, choose this condition. Don’t choose this
                            condition if you’ve been diagnosed with Impaired
                            Glucose Intolerance (IGT), also known as
                            pre-diabetes.<br />
                            <br />
                            <span class="font-weight-bold">Diverticulitis</span>
                            <br />
                            If you’ve only been diagnosed with diverticulosis,
                            don’t choose this condition. However, if you’ve been
                            diagnosed with diverticulitis and diverticulosis,
                            choose this condition.<br />
                            <br />
                            <span
                              class="font-weight-bold"
                            >Experimental treatment</span>
                            <br />
                            If you’ve been diagnosed with a condition and are
                            receiving experimental treatment for it, whether the
                            experimental treatment has been approved by Health
                            Canada or not, choose that condition.<br />
                            <br />
                            <span class="font-weight-bold">GERD</span> <br />
                            If you’ve been diagnosed with GERD that’s treated
                            with prescribed medication such as proton pump
                            inhibitors to stop the production of acid in the
                            stomach, choose this condition. Don’t choose this
                            condition if you have occasional heartburn or acid
                            reflux that hasn’t been diagnosed as GERD, even if
                            you’re taking over-the-counter medication for it
                            such as TUMS® or Rolaids®.<br />
                            <br />
                            <span class="font-weight-bold">Medical study</span>
                            <br />
                            If you’ve been diagnosed with a condition and are
                            participating in a medical study for it, choose that
                            condition.<br />
                            <br />

                            <v-card-text>
                              <v-divider></v-divider>

                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="primary"
                                  @click="
                                    dialog4 = false;
                                    clickedFieldGlobal(
                                      'travelInfo medTerminologyDialogCloseBtn',
                                      62,
                                      'Canadian Travel Survey v1'
                                    );
                                  "
                                >
                                  Close
                                </v-btn>
                              </v-card-actions>
                            </v-card-text>
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </div>
                  </v-col>
                </v-row>
                <v-row class="mt-0">
                  <v-col class="pt-0">
                    <v-btn
                      color="primary"
                      class="font-weight-bold"
                      dark
                      text
                      @click="
                        clickedFieldGlobal(
                          'travelInfo DLMedQuestionnaire',
                          63,
                          'Canadian Travel Survey v1'
                        )
                      "
                    >
                      <v-icon
                        color="primary"
                        left
                      >
                        mdi-download
                      </v-icon>
                      <a
                        class="pdf"
                        :href="`${publicPath}tugoMedicalForm.pdf`"
                        download="Medical Form"
                      >
                        Download the Medical Questionnaire</a>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-card-text>Rate Qualification Questions</v-card-text>
                <v-divider></v-divider>
                <div id="q1">
                  <v-card-text class="font-weight-bold">
                    1. Have you had any of the following? Choose the one most
                    recent event.
                  </v-card-text>
                  <v-card-text>
                    Myocardial infarction also known as heart attack, stroke or
                    Transient Ischemic Attack (TIA) also known as
                    mini-stroke
                  </v-card-text>
                  <v-radio-group
                    v-model="medicalSurvey.q1"
                    class="ml-4"
                  >
                    <v-radio
                      label="Less than 1 year ago"
                      value="q1a1"
                      @click="clickedField(`${medicalSurvey.q1}`, 74)"
                    ></v-radio>
                    <v-radio
                      label="1 to 5 years ago"
                      value="q1a2"
                      @click="clickedField(`${medicalSurvey.q1}`, 74)"
                    ></v-radio>
                    <v-radio
                      label="More than 5 years ago"
                      value="q1a3"
                      @click="clickedField(`${medicalSurvey.q1}`, 74)"
                    ></v-radio>
                    <v-radio
                      label="None"
                      value="q1a4"
                      @click="clickedField(`${medicalSurvey.q1}`, 74)"
                    ></v-radio>
                  </v-radio-group>
                </div>
                <div id="q3">
                  <v-card-text class="font-weight-bold">
                    2. Do you currently have hypertension also known as high
                    blood pressure?
                  </v-card-text>
                  <v-radio-group
                    v-model="medicalSurvey.q3"
                    class="ml-4"
                  >
                    <v-radio
                      label="No"
                      value="q3a1"
                      @click="clickedField(`${medicalSurvey.q3}`, 75)"
                    ></v-radio>
                    <div class="d-inline-flex align-center">
                      <v-radio
                        label="Yes with no alteration to medication in the last 6 months"
                        value="q3a2"
                        @click="clickedField(`${medicalSurvey.q3}`, 75)"
                      ></v-radio>
                      <v-tooltip
                        top
                        max-width="250px"
                        color="grey darken-3"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            class="ml-2 mb-2"
                            color="primary"
                            style="font-size: 30px;"
                            v-bind="attrs"
                            outline
                            v-on="on"
                          >
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>{{ surveyQuestions[0].tip }}</span>
                      </v-tooltip>
                    </div>
                    <div class="d-inline-flex align-center">
                      <v-radio
                        label="Yes with an alteration to medication in the last 6 months"
                        value="q3a3"
                        @click="clickedField(`${medicalSurvey.q3}`, 75)"
                      ></v-radio>
                      <v-tooltip
                        top
                        max-width="250px"
                        color="grey darken-3"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            small
                            class="ml-2 mb-2"
                            color="primary"
                            style="font-size: 30px;"
                            v-bind="attrs"
                            outline
                            v-on="on"
                          >
                            mdi-information-outline
                          </v-icon>
                        </template>
                        <span>{{ surveyQuestions[1].tip }}</span>
                      </v-tooltip>
                    </div>
                  </v-radio-group>
                </div>
                <div id="q4">
                  <v-row>
                    <v-col>
                      <v-card-text class="font-weight-bold">
                        3. Have you had any of these conditions in the last 12
                        months? Choose a condition if, in the specified
                        timeframe, you had the condition, whether or not you
                        received any medical care for it. This includes
                        conditions controlled or managed by medication, a
                        medical device, diet or exercise.
                      </v-card-text>
                      <v-card-text>CARDIOVASCULAR</v-card-text>
                      <v-container class="py-0">
                        <v-checkbox
                          v-model="medicalSurvey.q4"
                          class="pa-0 ma-0"
                          label="Aortic aneurysm that is 4cm or larger"
                          value="q4a1"
                          @click="
                            addAnswer4();
                            clickedField(`${medicalSurvey.q4}`, 76);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q4"
                          class="pa-0 ma-0"
                          label="Angina"
                          value="q4a2"
                          @click="
                            addAnswer4();
                            clickedField(`${medicalSurvey.q4}`, 76);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q4"
                          class="pa-0 ma-0"
                          label="Congestive heart failure also known as pulmonary edema"
                          value="q4a3"
                          @click="
                            addAnswer4();
                            clickedField(`${medicalSurvey.q4}`, 76);
                          "
                        ></v-checkbox>
                        <div class="d-inline-flex align-center">
                          <v-checkbox
                            v-model="medicalSurvey.q4"
                            class="pa-0 ma-0"
                            label="Coronary Artery Disease (CAD)"
                            value="q4a4"
                            @click="
                              addAnswer4();
                              clickedField(`${medicalSurvey.q4}`, 76);
                            "
                          ></v-checkbox>
                          <v-tooltip
                            top
                            max-width="250px"
                            color="grey darken-3"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                class="ml-2 mb-4"
                                color="primary"
                                style="font-size: 30px;"
                                v-bind="attrs"
                                outline
                                v-on="on"
                              >
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{ surveyQuestions[2].tip }}</span>
                          </v-tooltip>
                        </div>
                        <v-checkbox
                          v-model="medicalSurvey.q4"
                          class="pa-0 ma-0"
                          label="Deep Vein Thrombosis (DVT)"
                          value="q4a5"
                          @click="
                            addAnswer4();
                            clickedField(`${medicalSurvey.q4}`, 76);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q4"
                          class="pa-0 ma-0"
                          label="Arteriosclerosis and/or atherosclerosis also known as hardening of the arteries"
                          value="q4a6"
                          @click="
                            addAnswer4();
                            clickedField(`${medicalSurvey.q4}`, 76);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q4"
                          class="pa-0 ma-0"
                          label="Peripheral Vascular Disease (PVD)"
                          value="q4a7"
                          @click="
                            addAnswer4();
                            clickedField(`${medicalSurvey.q4}`, 76);
                          "
                        ></v-checkbox>
                        <div class="d-inline-flex align-center">
                          <v-checkbox
                            v-model="medicalSurvey.q4"
                            class="pa-0 ma-0"
                            label="Atrial fibrillation"
                            value="q4a8"
                            @click="
                              addAnswer4();
                              clickedField(`${medicalSurvey.q4}`, 76);
                            "
                          ></v-checkbox>
                          <v-tooltip
                            top
                            max-width="250px"
                            color="grey darken-3"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                class="ml-2 mb-4"
                                color="primary"
                                style="font-size: 30px;"
                                v-bind="attrs"
                                outline
                                v-on="on"
                              >
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{ surveyQuestions[3].tip }}</span>
                          </v-tooltip>
                        </div>
                        <v-checkbox
                          v-model="medicalSurvey.q4"
                          class="pa-0 ma-0"
                          label="High cholesterol"
                          value="q4a9"
                          @click="
                            addAnswer4();
                            clickedField(`${medicalSurvey.q4}`, 76);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="clearq4"
                          class="pa-0 ma-0"
                          label="None"
                          @click="
                            clearQuestion4();
                            clickedFieldGlobal(
                              'travelInfo medicalSurvey.q4 -None',
                              64,
                              'Canadian Travel Survey v1'
                            );
                          "
                        ></v-checkbox>
                      </v-container>
                      <v-card-text>RESPIRATORY</v-card-text>
                      <v-container class="py-0">
                        <v-checkbox
                          v-model="medicalSurvey.q5"
                          class="pa-0 ma-0"
                          label="Asthma requiring prednisone"
                          value="q5a1"
                          @click="
                            addAnswer5();
                            clickedField(`${medicalSurvey.q5}`, 77);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q5"
                          class="pa-0 ma-0"
                          label="Asthma not requiring prednisone"
                          value="q5a2"
                          @click="
                            addAnswer5();
                            clickedField(`${medicalSurvey.q5}`, 77);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q5"
                          class="pa-0 ma-0"
                          label="Two or more bronchitis episodes"
                          value="q5a3"
                          @click="
                            addAnswer5();
                            clickedField(`${medicalSurvey.q5}`, 77);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q5"
                          class="pa-0 ma-0"
                          label="Chronic Obstructive Pulmonary Disease (COPD), including emphysema, requiring home oxygen"
                          value="q5a4"
                          @click="
                            addAnswer5();
                            clickedField(`${medicalSurvey.q5}`, 77);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q5"
                          class="pa-0 ma-0"
                          label="Chronic Obstructive Pulmonary Disease (COPD), including emphysema, not requiring home oxygen"
                          value="q5a5"
                          @click="
                            addAnswer5();
                            clickedField(`${medicalSurvey.q5}`, 77);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="clearq5"
                          class="pa-0 ma-0"
                          label="None"
                          @click="
                            clearQuestion5();
                            clickedFieldGlobal(
                              'travelInfo medicalSurvey.q5 -None',
                              65,
                              'Canadian Travel Survey v1'
                            );
                          "
                        ></v-checkbox>
                      </v-container>
                      <v-card-text>GASTROINTESTINAL</v-card-text>
                      <v-container class="py-0">
                        <v-checkbox
                          v-model="medicalSurvey.q6"
                          class="pa-0 ma-0"
                          label="Peptic ulcer either stomach or duodenal"
                          value="q6a1"
                          @click="
                            addAnswer6();
                            clickedField(`${medicalSurvey.q6}`, 78);
                          "
                        ></v-checkbox>
                        <div class="d-inline-flex">
                          <v-checkbox
                            v-model="medicalSurvey.q6"
                            class="pa-0 ma-0"
                            label="Crohn's disease, diverticulitis , inflammatory bowel disease, bowel obstruction and/or ulcerative colitis"
                            value="q6a2"
                            @click="
                              addAnswer6();
                              clickedField(`${medicalSurvey.q6}`, 78);
                            "
                          ></v-checkbox>
                          <v-tooltip
                            top
                            max-width="250px"
                            color="grey darken-3"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                class="ml-2 mb-4 mr-12"
                                color="primary"
                                style="font-size: 30px;"
                                v-bind="attrs"
                                outline
                                v-on="on"
                              >
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{ surveyQuestions[4].tip }}</span>
                          </v-tooltip>
                        </div>
                        <div class="d-inline-flex">
                          <v-checkbox
                            v-model="medicalSurvey.q6"
                            class="pa-0 ma-0"
                            label="Gastroesophageal reflux disease (GERD) also known as chronic acid reflux"
                            value="q6a3"
                            @click="
                              addAnswer6();
                              clickedField(`${medicalSurvey.q6}`, 78);
                            "
                          ></v-checkbox>
                          <v-tooltip
                            top
                            max-width="250px"
                            color="grey darken-3"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                class="ml-2 mb-4"
                                color="primary"
                                style="font-size: 30px;"
                                v-bind="attrs"
                                outline
                                v-on="on"
                              >
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{ surveyQuestions[5].tip }}</span>
                          </v-tooltip>
                        </div>
                        <v-checkbox
                          v-model="clearq6"
                          class="pa-0 ma-0"
                          label="None"
                          @click="
                            clearQuestion6();
                            clickedFieldGlobal(
                              'travelInfo medicalSurvey.q6 -None',
                              66,
                              'Canadian Travel Survey v1'
                            );
                          "
                        ></v-checkbox>
                      </v-container>
                      <v-card-text>CANCER</v-card-text>
                      <v-container class="py-0">
                        <v-checkbox
                          v-model="medicalSurvey.q7"
                          class="pa-0 ma-0"
                          label="Pancreatic cancer"
                          value="q7a1"
                          @click="
                            addAnswer7();
                            clickedField(`${medicalSurvey.q7}`, 79);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q7"
                          class="pa-0 ma-0"
                          label="Liver cancer"
                          value="q7a2"
                          @click="
                            addAnswer7();
                            clickedField(`${medicalSurvey.q7}`, 79);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q7"
                          class="pa-0 ma-0"
                          label="Any type of cancer that has metastasized or that required a bone marrow transplant, excluding pancreatic or liver cancer"
                          value="q7a3"
                          @click="
                            addAnswer7();
                            clickedField(`${medicalSurvey.q7}`, 79);
                          "
                        ></v-checkbox>
                        <div class="d-inline-flex align-center">
                          <v-checkbox
                            v-model="medicalSurvey.q7"
                            class="pa-0 ma-0"
                            label="Any other types of cancer. Exclude basal cell and squamous cell skin cancer and/or cancer that is in remission"
                            value="q7a4"
                            @click="
                              addAnswer7();
                              clickedField(`${medicalSurvey.q7}`, 79);
                            "
                          ></v-checkbox>
                          <v-tooltip
                            top
                            max-width="250px"
                            color="grey darken-3"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                class="ml-2 mb-4"
                                color="primary"
                                style="font-size: 30px;"
                                v-bind="attrs"
                                outline
                                v-on="on"
                              >
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{ surveyQuestions[6].tip }}</span>
                          </v-tooltip>
                        </div>
                        <v-checkbox
                          v-model="clearq7"
                          class="pa-0 ma-0"
                          label="None"
                          @click="
                            clearQuestion7();
                            clickedFieldGlobal(
                              'travelInfo medicalSurvey.q7 -None',
                              67,
                              'Canadian Travel Survey v1'
                            );
                          "
                        ></v-checkbox>
                      </v-container>
                    </v-col>
                  </v-row>
                </div>
                <div id="q4">
                  <v-row>
                    <v-col>
                      <v-card-text class="font-weight-bold">
                        4. Have you had any of these conditions in the last 12
                        months? Choose a condition if, in the specified
                        timeframe, you had the condition, whether or not you
                        received any medical care for it. This includes
                        conditions controlled or managed by medication, a
                        medical device, diet or exercise.
                      </v-card-text>
                      <v-container class="py-0">
                        <div class="d-inline-flex">
                          <v-checkbox
                            v-model="medicalSurvey.q8"
                            class="pa-0 ma-0"
                            label="Arthritis"
                            value="q8a1"
                            @click="
                              addAnswer8();
                              clickedField(`${medicalSurvey.q8}`, 80);
                            "
                          ></v-checkbox>
                          <v-tooltip
                            top
                            max-width="250px"
                            color="grey darken-3"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                class="ml-2 mb-4"
                                color="primary"
                                style="font-size: 30px;"
                                v-bind="attrs"
                                outline
                                v-on="on"
                              >
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{ surveyQuestions[7].tip }}</span>
                          </v-tooltip>
                        </div>
                        <v-checkbox
                          v-model="medicalSurvey.q8"
                          class="pa-0 ma-0"
                          label="Diabetes requiring insulin"
                          value="q8a2"
                          @click="
                            addAnswer8();
                            clickedField(`${medicalSurvey.q8}`, 80);
                          "
                        ></v-checkbox>
                        <div class="d-inline-flex align-center">
                          <v-checkbox
                            v-model="medicalSurvey.q8"
                            class="pa-0 ma-0"
                            label="Diabetes not requiring insulin"
                            value="q8a3"
                            @click="
                              addAnswer8();
                              clickedField(`${medicalSurvey.q8}`, 80);
                            "
                          ></v-checkbox>
                          <v-tooltip
                            top
                            max-width="250px"
                            color="grey darken-3"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                class="ml-2 mb-4"
                                color="primary"
                                style="font-size: 30px;"
                                v-bind="attrs"
                                outline
                                v-on="on"
                              >
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{ surveyQuestions[8].tip }}</span>
                          </v-tooltip>
                        </div>
                        <v-checkbox
                          v-model="medicalSurvey.q8"
                          class="pa-0 ma-0"
                          label="Gallbladder disease, including gallstones"
                          value="q8a4"
                          @click="
                            addAnswer8();
                            clickedField(`${medicalSurvey.q8}`, 80);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q8"
                          class="pa-0 ma-0"
                          label="Hyperthyroidism"
                          value="q8a5"
                          @click="
                            addAnswer8();
                            clickedField(`${medicalSurvey.q8}`, 80);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q8"
                          class="pa-0 ma-0"
                          label="Hypothyroidism"
                          value="q8a6"
                          @click="
                            addAnswer8();
                            clickedField(`${medicalSurvey.q8}`, 80);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q8"
                          class="pa-0 ma-0"
                          label="Kidney stones"
                          value="q8a7"
                          @click="
                            addAnswer8();
                            clickedField(`${medicalSurvey.q8}`, 80);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q8"
                          class="pa-0 ma-0"
                          label="Kidney disease requiring dialysis"
                          value="q7a8"
                          @click="
                            addAnswer8();
                            clickedField(`${medicalSurvey.q8}`, 80);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q8"
                          class="pa-0 ma-0"
                          label="Kidney disease not requiring dialysis"
                          value="q8a9"
                          @click="
                            addAnswer8();
                            clickedField(`${medicalSurvey.q8}`, 80);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q8"
                          class="pa-0 ma-0"
                          label="Dementia including Alzheimer’s disease"
                          value="q8a10"
                          @click="
                            addAnswer8();
                            clickedField(`${medicalSurvey.q8}`, 80);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q8"
                          class="pa-0 ma-0"
                          label="Osteoporosis"
                          value="q8a11"
                          @click="
                            addAnswer8();
                            clickedField(`${medicalSurvey.q8}`, 80);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q8"
                          class="pa-0 ma-0"
                          label="Parkinson’s Disease (PD)"
                          value="q8a12"
                          @click="
                            addAnswer8();
                            clickedField(`${medicalSurvey.q8}`, 80);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q8"
                          class="pa-0 ma-0"
                          label="Pancreatitis"
                          value="q8a13"
                          @click="
                            addAnswer8();
                            clickedField(`${medicalSurvey.q8}`, 80);
                          "
                        ></v-checkbox>
                        <v-checkbox
                          v-model="medicalSurvey.q8"
                          class="pa-0 ma-0"
                          label="Liver disease, excluding liver cancer"
                          value="q8a14"
                          @click="
                            addAnswer8();
                            clickedField(`${medicalSurvey.q8}`, 80);
                          "
                        ></v-checkbox>
                        <div class="d-inline-flex align-center">
                          <v-checkbox
                            v-model="medicalSurvey.q8"
                            class="pa-0 ma-0"
                            label="Two or more bladder infections"
                            value="q8a15"
                            @click="
                              addAnswer8();
                              clickedField(`${medicalSurvey.q8}`, 80);
                            "
                          ></v-checkbox>
                          <v-tooltip
                            top
                            max-width="250px"
                            color="grey darken-3"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                small
                                class="ml-2 mb-4"
                                color="primary"
                                style="font-size: 30px;"
                                v-bind="attrs"
                                outline
                                v-on="on"
                              >
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span>{{ surveyQuestions[9].tip }}</span>
                          </v-tooltip>
                        </div>
                        <v-checkbox
                          v-model="clearq8"
                          class="pa-0 ma-0"
                          label="None"
                          @click="
                            clearQuestion8();
                            clickedFieldGlobal(
                              'travelInfo medicalSurvey.q8 -None',
                              68,
                              'Canadian Travel Survey v1'
                            );
                          "
                        ></v-checkbox>
                      </v-container>
                    </v-col>
                  </v-row>
                  <div id="q5">
                    <v-card-text class="font-weight-bold">
                      5. In the last 12 months, have you smoked any tobacco
                      products, or vaped any nicotine products (including
                      e-cigarettes)?
                    </v-card-text>
                    <v-radio-group
                      v-model="medicalSurvey.q9"
                      class="ml-4"
                    >
                      <v-radio
                        label="Yes"
                        value="q9a1"
                        @click="clickedField(`${medicalSurvey.q9}`, 81)"
                      ></v-radio>
                      <v-radio
                        label="No"
                        value="q9a2"
                        @click="clickedField(`${medicalSurvey.q9}`, 81)"
                      ></v-radio>
                    </v-radio-group>
                  </div>
                </div>
              </div>
              <!--------------------------Continue----------------------------->
              <div v-if="surveyComplete">
                <v-row>
                  <v-col>
                    <v-card-text>Your questionnaire is complete.</v-card-text>
                    <v-card-text>
                      Thanks for completing the questionnaire. Once you save
                      your responses, we’ll provide you with a
                      quote.
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-row
                  v-if="loading"
                  class="mt-12"
                >
                  <v-col class="d-flex justify-center">
                    <ellipse-progress
                      :loading="loading"
                      :size="180"
                      img-src="src/assets/images/Travel-Icon.png"
                      thickness="5"
                      empty-thickness="3"
                      line-mode="out 5"
                      legend="false"
                      animation="rs 700 1000"
                      font-size="1.5rem"
                      color="#00A1B7"
                      empty-color-fill="transparent"
                      filename="Travel-Icon.png"
                    >
                    </ellipse-progress>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-btn
                      color="primary"
                      class="white--text font-weight-bold ml-4"
                      @click="
                        saveSurvey();
                        clickedFieldGlobal(
                          'travelInfo saveQuestionnaire',
                          69,
                          'Canadian Travel Survey v1'
                        );
                      "
                    >
                      Save Questionnaire
                    </v-btn>
                    <v-btn
                      :disabled="!getQuote || birthDayError || loading"
                      color="primary"
                      class="white--text font-weight-bold ml-4"
                      @click="
                        next();
                        clickedFieldGlobal(
                          'travelInfo getQuotesAfterQuestionnaire',
                          70,
                          'Canadian Travel Survey v1'
                        );
                      "
                    >
                      Get Quotes
                    </v-btn>
                    <v-dialog
                      v-model="completeDialog"
                      transition="dialog-bottom-transition"
                      max-width="600"
                    >
                      <template v-slot:default="dialog">
                        <v-card>
                          <v-toolbar
                            color="primary"
                            dark
                          >
                            Thank You!
                          </v-toolbar>
                          <v-card-text>
                            <div class="text-h2 pa-12 text-center">
                              Questionnaire Saved
                            </div>
                          </v-card-text>
                          <v-card-actions class="justify-end">
                            <v-btn
                              text
                              @click="
                                dialog.value = false;
                                clickedFieldGlobal(
                                  'travelInfo closeQuestionnaireDialogBtn',
                                  71,
                                  'Canadian Travel Survey v1'
                                );
                              "
                            >
                              Close
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </template>
                    </v-dialog>
                  </v-col>
                </v-row>
              </div>
            </v-form>
          </validation-observer>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="space-around">
      <v-col cols="auto">
        <v-dialog
          v-model="birthDayErrorDialog"
          max-width="700"
          persistent
        >
          <v-card min-height="33vh">
            <v-card-title class="justify-center font-weight-bold text-h5">
              Birth Date Error
            </v-card-title>
            <v-card-text class="justify-center text-center body primmary--text">
              <v-row>
                <v-col>
                  {{ birthDayErrorMsg }}
                </v-col>
              </v-row>
            </v-card-text>
            <v-row no-gutters>
              <v-col>
                <div class="d-flex justify-center">
                  <v-btn
                    color="primary"
                    class="mb-4 mt-4 font-weight-bold"
                    @click="
                      birthDayErrorDialog = false;
                      clickedFieldGlobal(
                        'travelInfo birthDayErrorDialogCloseBtn',
                        72,
                        'Canadian Travel Survey v1'
                      );
                    "
                  >
                    Close
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-dialog
      v-model="errorDialog"
      max-width="700"
      persistent
    >
      <v-card min-height="33vh">
        <v-card-title class="justify-center font-weight-bold text-h5">
          We are unable to process your request
        </v-card-title>
        <v-card-text class="justify-center text-center body primmary--text">
          <v-row>
            <v-col>
              We’re sorry that we are unable to process your application at this
              time due to the following error. Please contact us for support
              with your application.
            </v-col>
          </v-row>
          <v-row v-if="error">
            <v-col>{{ error }}</v-col>
          </v-row>
        </v-card-text>
        <v-row no-gutters>
          <v-col>
            <div class="d-flex justify-center">
              <v-btn
                color="primary"
                class="mb-4 mt-4 font-weight-bold"
                @click="
                  clearError();
                  clickedFieldGlobal(
                    'travelInfo returnToHome',
                    73,
                    'Canadian Travel Survey v1'
                  );
                "
              >
                Return to Home
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
// import {
//   countries,
//   cities,
//   getCitiesByCountryCode,
// } from 'country-city-location'
  import moment from 'moment'
  import { debounce } from '@/utils/debounce'
  const util = require('util')
  let { AgeFromDateString, AgeFromDate } = require('age-calculator')
  export default {
    data () {
      return {
        birthDayErrorMsg: '',
        activeBirthDatePicker: null,
        activeAdditionalTravelerIndex: null,
        birthDate: null,
        birthDateMenu: false,
        errorDialog: false,
        birthDayErrorDialog: false,
        birthDayError: false,
        medicalSurvey: {
          q1: '',
          q3: '',
          q4: [],
          q5: [],
          q6: [],
          q7: [],
          q8: [],
          q9: ''
        },
        alertmessage: '',
        snackbar: false,
        bdays: '',
        bmonths: '',
        byears: '',
        bdaysSecond: '',
        bmonthsSecond: '',
        byearsSecond: '',
        clearq4: false,
        clearq5: false,
        clearq6: false,
        clearq7: false,
        clearq8: false,
        getQuote: false,
        additionalTraveler: [
          'Traveller Number 2',
          'Traveller Number 3',
          'Traveller Number 4',
          'Traveler Number 5',
          'Traveller Number 6'
        ],
        additionalTravelers: [],
        menu: false,
        completeDialog: false,
        publicPath: process.env.BASE_URL,
        surveyAnswers: [{ code: 'QT-TUP-1A', answer: '' }],
        showButton: false,
        dialog3: false,
        dialog4: false,
        dialog5: false,
        destination: '',
        origin: '',
        living: '',
        addTravelerDisabled: false,
        primaryTravellerName: '',
        primaryTravellerDob: '',
        primaryTravellerAge: '',
        secondTravellerName: '',
        secondTravellerAge: '',
        tripCost: '',
        coverage: '',
        numberOfTravellers: '',
        coverageOptions: ['Small', 'Medium', 'Large'],
        minDate: moment()
          .subtract(100, 'years')
          .format('YYYY-MM-DD'),
        startDate: moment()
          .add(1, 'day')
          .format('YYYY-MM-DD'),
        resetDate: moment()
          .add(1, 'day')
          .format('YYYY-MM-DD'),
        endDate: moment()
          .add(8, 'day')
          .format('YYYY-MM-DD'),
        checkbox: true,
        modal1: null,
        modal2: null,
        provinces: [
          'Alberta',
          'British Columbia',
          'Manitoba',
          'New Brunswick',
          'Newfoundland and Labrador',
          'Nova Scotia',
          'Ontario',
          'Prince Edward Island',
          'Saskatchewan'
        ],
        menu1: false,
        menu2: false,
        numbers: [1, 2],
        destinations: [
          'Destination 1',
          'Destination 2',
          'Destination 3',
          'Destination 4'
        ],
        primaryDestinations: ['p1', 'p2', 'p3', 'p4'],
        surveyQuestions: [
          {
            code: 'q3a2',
            tip:
              'The medication usage, dosage or type has been increased, decreased or stopped and/or a new medication has been prescribed.Alteration does not include: a) Changes in brand to an equivalent name brand or to an equivalent generic brand of the same or equivalent usage or dosage; or, b) Routine dosage adjustments within prescribed parameters for insulin or oral diabetes medication to ensure correct blood levels are maintained; blood sugar levels must be checked regularly and the medical condition must remain unchanged; or, c) Routine dosage adjustments within prescribed parameters for blood thinner medication to ensure correct blood levels are maintained; blood levels must be checked regularly and the medical condition must remain unchanged; or, d) A temporary stoppage of blood thinner medication up to a maximum of 24 hours if the stoppage is required for a surgery or a procedure; or, e) Usage changes due to the combination of several medications into one; the medical condition  must remain unchanged.'
          },
          {
            code: 'q3a3',
            tip:
              'The medication usage, dosage or type has been increased, decreased or stopped and/or a new medication has been prescribed.Alteration does not include: a) Changes in brand to an equivalent name brand or to an equivalent generic brand of the same or equivalent usage or dosage; or,b) Routine dosage adjustments within prescribed parameters for insulin or oral diabetes medication to ensure correct blood levels are maintained; blood sugar levels must be checked regularly and the medical condition must remain unchanged; or,c) Routine dosage adjustments within prescribed parameters for blood thinner medication to ensure correct blood levels are maintained; blood levels must be checked regularly and the medical condition must remain unchanged; or,d) A temporary stoppage of blood thinner medication up to a maximum of 24 hours if the stoppage is required for a surgery or a procedure; or,e) Usage changes due to the combination of several medications into one; the medical condition  must remain unchanged.'
          },
          {
            code: 'q4a4',
            tip:
              'Coronary Artery Disease can be caused by arteriosclerosis and/or atherosclerosis. If you’ve been diagnosed with CAD and arteriosclerosis and/or atherosclerosis, choose all these conditions.'
          },
          {
            code: 'q4a8',
            tip:
              'Atrial fibrillation is a type of arrhythmia. Choose this condition if you’ve been diagnosed specifically with atrial fibrillation and not any other type of arrhythmia or dysrhythmia.'
          },
          {
            code: 'q6a2',
            tip:
              'If you’ve only been diagnosed with diverticulosis, don’t choose this condition. However, if you’ve been diagnosed with diverticulitis and diverticulosis, choose this condition.'
          },
          {
            code: 'q6a3',
            tip:
              'If you’ve been diagnosed with GERD that’s treated with prescribed medication such as proton pump inhibitors to stop the production of acid in the stomach, choose this condition. Don’t choose this condition if you have occasional heartburn or acid reflux that hasn’t been diagnosed as GERD, even if you’re taking over-the-counter medication for it such as TUMS® or Rolaids®.'
          },
          {
            code: 'q7a4',
            tip:
              'The decrease in or the disappearance of signs and symptoms of cancer and/or the removal of cancer as determined by your physician and noted in your medical records. Remission can be complete or partial. Complete remission means the disappearance of all signs or symptoms. Partial remission means a decrease in or disappearance of some, but not all, signs and symptoms.'
          },
          {
            code: 'q8a1',
            tip:
              'If you’ve been diagnosed with any type of arthritis such as osteoarthritis, rheumatoid arthritis or psoriatic arthritis, choose this condition.'
          },
          {
            code: 'q8a3',
            tip:
              'If you’ve been diagnosed with diabetes that’s treated either by lifestyle changes such as diet, exercise or with any kind of medication other than insulin, choose this condition. Don’t choose this condition if you’ve been diagnosed with Impaired Glucose Intolerance (IGT), also known as pre-diabetes.'
          },
          {
            code: 'q8a15',
            tip:
              'A bladder infection is a specific type of Urinary Tract Infection (UTI). Choose this condition if you’ve had two or more bladder infections, and not any other types of UTIs.'
          }
        ]
      }
    },
    computed: {
      loading () {
        return this.$store.getters.getloading
      },
      error () {
        let error = this.$store.getters.error
        if (error) {
          this.errorDialog = true
          return error
        }
        return false
      },
      infoIncomplete () {
        return true
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      calcbday () {
        console.log('CALC B DAY ')
        let dateofbirth = '-'
        console.log(this.byears + '-' + this.bmonths + '-' + this.bdays)
        if (this.byears && this.bmonths && this.bdays) {
          dateofbirth = moment(
            parseInt(this.byears) +
              '-' +
              this.bmonths +
              '-' +
              parseInt(this.bdays),
            'YYYY-MMM-DD'
          ).toDate()
          let now = moment().toDate()
          let monthnum = moment()
            .month(this.bmonths)
            .format('MM')

          // get current year
          let ageFromString = new AgeFromDateString(
            this.byears + '-' + monthnum + '-' + this.bdays
          ).age
          console.log('value from ageFromString', ageFromString)
          // get month number
          let as = this.byears + '/' + monthnum + '/' + this.bdays
          console.log(as)
          let birthDate = new Date(as.toString())
          console.log(' birthDate' + birthDate)

          // get difference from current date;
          let difference = Date.now() - birthDate.getTime()

          let ageDate = new Date(difference)
          let calculatedAge = Math.abs(ageDate.getUTCFullYear() - 1970)
          console.log('age calc from pure js ', calculatedAge)
          this.primaryTravellerDob = moment(dateofbirth).format('YYYY-MM-DD')
          this.primaryTravellerAge = calculatedAge
          console.log(
            'ptrav age',
            this.primaryTravellerAge,
            this.primaryTravellerDob
          )
        } else {
          dateofbirth = '-'
        }
        return dateofbirth
      },
      yearsavailable () {
        let maxyear = parseInt(moment().format('YYYY'))
        let startingyear = maxyear - 70
        let years = []
        for (let i = startingyear; i <= maxyear; i++) {
          years.push(i.toString())
        }
        return years.reverse()
      },
      monthsavailable () {
        let months = moment.monthsShort()
        let ucMonths = []
        months.map(entry => {
          ucMonths.push(entry.toUpperCase())
        })
        return ucMonths
      },
      daysavailable () {
        let days = []
        let lastday = 31
        for (let i = 1; i <= lastday; i++) {
          days.push(i.toString())
        }
        return days
      },
      surveyComplete () {
        return (
          this.medicalSurvey.q1 &&
          this.medicalSurvey.q3 &&
          this.medicalSurvey.q4 !== [] &&
          this.medicalSurvey.q5 !== [] &&
          this.medicalSurvey.q6 !== [] &&
          this.medicalSurvey.q7 !== [] &&
          this.medicalSurvey.q8 !== [] &&
          this.medicalSurvey.q9
        )
      },
      incomplete () {
        return this.origin && this.destination && this.tripCost
      },
      questions () {
        return this.$store.getters.getTravelCanadaSurveys.data.items[0].questions
      },
      over60 () {
        if (this.primaryTravellerAge >= 60 || this.secondTravellerAge >= 60) {
          return true
        }
        return false
      },
      tripLength () {
        let end = moment(this.endDate)
        let start = moment(this.startDate)
        return end.diff(start, 'days') + 1
      },
      maxDate () {
        return moment()
          .add(2, 'y')
          .format('YYYY-MM-DD')
      },
      minEndDate () {
        return moment(this.startDate)
          .add(1, 'd')
          .format('YYYY-MM-DD')
      },
      places () {
        let list = []
        list = [
          { Label: 'Inside Canada', code: 'DESTWWCAN' },
          { Label: 'World Wide including USA', code: 'DESTWW' },
          { Label: 'World Wide excluding USA', code: 'DESTWWUSA' }
        ]
        // list = list.concat(this.provinces, this.countries)
        console.log(list)
        return list
      },
      travelDetails () {
        return {
          destination: this.destination.code,
          primaryTravellerAge: this.primaryTravellerAge,
          primaryTravellerName: this.primaryTravellerName,
          additionalTravelers: this.additionalTravelers,
          primaryDestination: this.primaryDestination,
          origin: this.origin,
          primaryTravellerDob: this.primaryTravellerDob,
          tripCost: this.tripCost,
          numberOfTravellers: this.numberOfTravellers,
          startDate: this.startDate,
          endDate: this.endDate
        }
      }
    },
    watch: {
      birthDateMenu (val) {
        val && setTimeout(() => (this.activeBirthDatePicker = 'YEAR'))
      },
      additionalTravelers: {
        handler (val) {
          val && this.activeAdditionalTravelerIndex !== null && setTimeout(() => (val[this.activeAdditionalTravelerIndex].prevBirthDateMenu = val[this.activeAdditionalTravelerIndex].birthDateMenu))
          val && val[this.activeAdditionalTravelerIndex]?.birthDateMenu !== val[this.activeAdditionalTravelerIndex]?.prevBirthDateMenu && setTimeout(() => (val[this.activeAdditionalTravelerIndex].activeBirthDatePicker = 'YEAR'))
        },
        deep: true
      },
    },
    created () {
      this.resetDate = moment()
        .add(1, 'day')
        .format('YYYY-MM-DD')
    },
    methods: {
      setActiveAdditionalTravelerIndex (index) {
        this.activeAdditionalTravelerIndex = index
      },
      calculateAge (date) {
        const currentDate = moment()
        const birthdateMoment = moment(date, 'YYYY-MM-DD')

        // Calculate the difference in years between the current date and the birthdate
        const age = currentDate.diff(birthdateMoment, 'years')

        if (age === 0) return 1
        return age
      },
      saveBirthDate (date, ref) {
        if (ref === 'birthDateMenu') {
          this.primaryTravellerAge = this.calculateAge(date)
          this.primaryTravellerDob = moment(date).format('YYYY-MM-DD')
          this.$refs[ref].save(date)
        } else {
          this.$refs[ref][0].save(date)
        }
      },
      debouncedSave: debounce(function (updatedItem) {
        updatedItem.debouncedName = updatedItem.name
      }, 500),
      scroll () {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      },
      setEndDate () {
        this.endDate = moment(this.startDate)
          .add(1, 'w')
          .format('YYYY-MM-DD')
      },
      clearError () {
        this.$store.dispatch('clearError')
        this.errorDialog = false
        this.$router.push({ path: '/' })
      },
      checkDate (bday, bmonth, byear) {
        if (bday && bmonth && byear) {
          bday = bday.toString()
          bmonth = bmonth.toString()
          byear = byear.toString()
          let dob = `${bmonth}-${bday}-${byear}`
          let isoFormatted = moment(dob, 'MMM-DD-YYYY').format('YYYY-MM-DD')
          console.log('ISO Formatted', isoFormatted)
          if (moment(isoFormatted).isAfter(moment().format('YYYY-MM-DD'))) {
            console.log('date is in the future')
            this.birthDayErrorDialog = true
            this.birthDayError = true
            this.birthDayErrorMsg = 'Date of birth cannot be in the future'
          } else {
            this.birthDayErrorDialog = false
            this.birthDayError = false
          }
        }
      },
      resetErrorResponse () {
        this.alertmessage = ''
        this.snackbar = false
      },
      saveTravelers () {
        console.log(this.additionalTravelers)
      // filter out the empty ones
      },
      addTravelerCount () {
        if (this.additionalTravelers.length < 5) {
          let travellerNumber = this.additionalTravelers.length + 1
          let rec = {
            debouncedName: '',
            name: '',
            birthyear: '',
            birthmonth: '',
            birthday: '',
            birthdate: '',
            birthDateMenu: false,
            activeBirthDatePicker: 'YEAR',
            tNumber: `${travellerNumber}`,
            index: this.additionalTravelers.length
          }
          this.additionalTravelers.push(rec)
        }
      },
      removeTraveler (item, key) {
        console.log('item to remove', item, ' location ', key)
        this.additionalTravelers.splice(key, 1)
        this.setActiveAdditionalTravelerIndex(null)
        console.log('now length is ', this.additionalTravelers.length)
      },
      saveSurvey () {
        this.$store.dispatch('setTugoMedicalSurvey', this.medicalSurvey)
        this.completeDialog = true
        this.getQuote = true
      },
      clearQuestion4 () {
        if (this.clearq4) {
          this.medicalSurvey.q4 = ['q4a10']
          console.log(this.medicalSurvey.q4)
        }
      },
      addAnswer4 () {
        if (this.medicalSurvey.q4.includes('q4a10')) {
          let index = this.medicalSurvey.q4.indexOf('q4a10')
          this.medicalSurvey.q4.splice(index, 1)
          this.clearq4 = false
        }
        console.log(this.medicalSurvey.q4)
      },
      clearQuestion5 () {
        if (this.clearq5) {
          this.medicalSurvey.q5 = ['q5a6']
          console.log(this.medicalSurvey.q5)
        }
      },
      addAnswer5 () {
        if (this.medicalSurvey.q5.includes('q5a6')) {
          let index = this.medicalSurvey.q5.indexOf('q5a6')
          this.medicalSurvey.q5.splice(index, 1)
          this.clearq5 = false
        }
        console.log(this.medicalSurvey.q5)
      },
      clearQuestion6 () {
        if (this.clearq6) {
          this.medicalSurvey.q6 = ['q6a4']
          console.log(this.medicalSurvey.q6)
        }
      },
      addAnswer6 () {
        if (this.medicalSurvey.q6.includes('q6a4')) {
          let index = this.medicalSurvey.q6.indexOf('q6a4')
          this.medicalSurvey.q6.splice(index, 1)
          this.clearq6 = false
        }
        console.log(this.medicalSurvey.q6)
      },
      clearQuestion7 () {
        if (this.clearq7) {
          this.medicalSurvey.q7 = ['q7a5']
          console.log(this.medicalSurvey.q7)
        }
      },
      addAnswer7 () {
        if (this.medicalSurvey.q7.includes('q7a5')) {
          let index = this.medicalSurvey.q7.indexOf('q7a5')
          this.medicalSurvey.q7.splice(index, 1)
          this.clearq7 = false
        }
        console.log(this.medicalSurvey.q7)
      },
      clearQuestion8 () {
        if (this.clearq8) {
          this.medicalSurvey.q8 = ['q8a16']
          console.log(this.medicalSurvey.q8)
        }
      },
      addAnswer8 () {
        if (this.medicalSurvey.q8.includes('q8a16')) {
          let index = this.medicalSurvey.q8.indexOf('q8a16')
          this.medicalSurvey.q8.splice(index, 1)
          this.clearq8 = false
        }
        console.log(this.medicalSurvey.q8)
      },
      checkValue (value, model) {
        if (value === 'none') {
          model = []
        }
      },
      tipExists (code) {
        return this.surveyQuestions.some(function (el) {
          return el.code === code
        })
      },
      getTip (questionCode) {
        let tip = this.surveyQuestions.find(({ code }) => questionCode === code)
        if (!tip) {
          return false
        } else {
          return tip.tip
        }
      },
      async next () {
        const complete = await this.$refs.observer.validate()
        this.scroll()
        console.log('details sent to actions ', this.travelDetails)
        // check here for last chance to find bad data and show an error pop up
        if (!complete) {
          console.log('Error validating form')
        } else {
          // get the additional travelers
          if (this.travelDetails.additionalTravelers.length > 0) {
            this.travelDetails.additionalTravelers.forEach(traveler => {
              console.log('traveler', traveler)
              Object.assign(traveler, { age: this.calculateAge(traveler.birthdate) })
            })
          }
          console.log(
            'ADDITIONAL TRAVELERS',
            this.travelDetails.additionalTravelers
          )
          this.$store
            .dispatch('setTravelDetails', this.travelDetails)
            .then(() => {
              this.$store
                .dispatch('getTravelQuoteCanada', this.travelDetails)
                .then(() => {
                  if (!this.error) {
                    this.$router.push({ path: '/travelcoverageoptionscanada' })
                  }
                })
            })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.survey-alert {
  background-color: #ff9800;
}
.pdf {
  text-decoration: none;
}
.list {
  list-style: none;
}

.vh-75 {
  min-height: 75vh;
}
</style>
